<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">活動產品</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增活動產品
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows">
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <SelectBox v-bind="activitySelectOptions" v-model="selectedActivity" class="mr-2" />
            <vxe-input type="search" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" clearable="true" class="w-full" @keyup="
              (e) => {
                if (e.$event.keyCode === 13) grid.refresh();
              }
            " @clear="grid.refresh()" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @addNewRow="(row, callback) => { row.IsRed = false; callback(); }">
          <template #modal="{ row, submit, reset }">
            <VxeForm ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template #column-activity-id="{ data }">
                <SelectBox v-bind="activitySelectOptions" v-model="data.ActivityId" />
              </template>
              <template #column-product-ids="{ data }">
                <SelectBox v-bind="productSelectOptions" v-model="data.ProductIds" />
              </template>
            </VxeForm>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const selectedActivity = ref(0);

    const gridOptions: GridOptions = {
      title: "活動產品",
      canCreate: true,
      canUpdate: false,
      canRead: false,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "Activity.Name", title: "活動", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Product.Name", title: "產品", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "IsRed", title: "是否為紅標", showHeaderOverflow: true, showOverflow: true, align: "center", sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (selectedActivity.value) params.condition!.and("Activity.Id", Operator.Equal, selectedActivity.value);
            return model.dispatch('activityProduct/query', params)
          }
          : undefined,
        save: model
          ? (params) => model.dispatch('activityProduct/save', params)
          : undefined
      },
      modalConfig: { width: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'ActivityId', title: '活動', span: 24, slots: { default: "column-activity-id" } },
        { field: 'ProductIds', title: '產品', span: 24, slots: { default: "column-product-ids" } },
        { field: "IsRed", title: "是否為紅標", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
      ],
      rules: {
        ActivityId: [{ required: true }],
        ProductIds: [{ required: true }],
        IsRed: [{ required: true }],
      }
    }

    watch(selectedActivity, value => {
      grid.value.refresh();
    })

    const activitySelectOptions: SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇活動',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("activity/find", value),
        query: (params) => model!.dispatch("activity/query", params) // eslint-disable-line
      }
    }

    const productSelectOptions: SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇產品',
      textField: 'Name',
      valueField: 'Id',
      multiselect: true,
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("product/find", value),
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      selectedActivity,
      activitySelectOptions,
      productSelectOptions
    };
  }
});
</script>
