
import { defineComponent, ref, watch } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const selectedActivity = ref(0);

    const gridOptions: GridOptions = {
      title: "活動產品",
      canCreate: true,
      canUpdate: false,
      canRead: false,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "Activity.Name", title: "活動", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Product.Name", title: "產品", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "IsRed", title: "是否為紅標", showHeaderOverflow: true, showOverflow: true, align: "center", sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (selectedActivity.value) params.condition!.and("Activity.Id", Operator.Equal, selectedActivity.value);
            return model.dispatch('activityProduct/query', params)
          }
          : undefined,
        save: model
          ? (params) => model.dispatch('activityProduct/save', params)
          : undefined
      },
      modalConfig: { width: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'ActivityId', title: '活動', span: 24, slots: { default: "column-activity-id" } },
        { field: 'ProductIds', title: '產品', span: 24, slots: { default: "column-product-ids" } },
        { field: "IsRed", title: "是否為紅標", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
      ],
      rules: {
        ActivityId: [{ required: true }],
        ProductIds: [{ required: true }],
        IsRed: [{ required: true }],
      }
    }

    watch(selectedActivity, value => {
      grid.value.refresh();
    })

    const activitySelectOptions: SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇活動',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("activity/find", value),
        query: (params) => model!.dispatch("activity/query", params) // eslint-disable-line
      }
    }

    const productSelectOptions: SelectBoxOptions = {
      transfer: true,
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇產品',
      textField: 'Name',
      valueField: 'Id',
      multiselect: true,
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("product/find", value),
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      selectedActivity,
      activitySelectOptions,
      productSelectOptions
    };
  }
});
